const BASEURL = process.env.REACT_APP_BACKEND;

const setBaseUrlPrefix = (path) => {
  return BASEURL.concat(path);
};

const dashboardPathConstants = {
    //no authentication needed dashboard for events
    upcomingEvents: {
        getUpcomingEvent: setBaseUrlPrefix("/home/getEvent"),
        getEventCategories: setBaseUrlPrefix("/home/getEventCategories"),
        getEventContentById: setBaseUrlPrefix("/home/getEventContent"),
        getEventOrganizerById: setBaseUrlPrefix("/home/getEventOrganizer"),
    },
    //no authentication needed dashboard for movies
    upcomingMovies: {
        getUpcomingMovies: setBaseUrlPrefix("/home/getMovies"),
        getMovieCategories: setBaseUrlPrefix("/home/getMovieGenres"),
        getMovieContentById: setBaseUrlPrefix("/home/getMovieContent"),
        getMovieByOrganizerId: setBaseUrlPrefix("/home/getMovieOrganizer"),
    },
    wishList: {
        handleLikedEvent: setBaseUrlPrefix("/home/likedEvent"),
        handleDislikedEvent: setBaseUrlPrefix("/home/dislikeEvent"),
        handleWishList: setBaseUrlPrefix("/home/wishlist"),
    },
    supportTicket: {
        customerTicketList: setBaseUrlPrefix("/support/ticketsList"),
        customerAddTicketList: setBaseUrlPrefix("/support/addTicketCus"),
        orgAddTicketList: setBaseUrlPrefix("/support/addTicketOrg"),
        orgTicketList: setBaseUrlPrefix("/support/ticketsListOrg"),
    },
    paymentTicket: {
        holdTicketList: setBaseUrlPrefix("/book/holdTicket"),
        movieHoldTicketList: setBaseUrlPrefix("/book/movieHoldTicket"),
        handleWebHook: setBaseUrlPrefix("/book/webhook"),
        handlePaymentVerification: setBaseUrlPrefix("/book/payment"),
        handlePayPal:setBaseUrlPrefix("/book/paymentPaypal"),
        handleFreeTicket:setBaseUrlPrefix("/book/freeTicket")
    }
};

export default dashboardPathConstants;
