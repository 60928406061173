const BASEURL = process.env.REACT_APP_BACKEND;

const setBaseUrlPrefix = (path) => {
  return BASEURL.concat(path);
};



const apiPathConstants = {
  auth: {
    googleLogin:setBaseUrlPrefix("/google"),
    facebookLogin:setBaseUrlPrefix("/facebook"),
    login: setBaseUrlPrefix("/auth/login"),
    logout: setBaseUrlPrefix("/auth/logout"),
    register: setBaseUrlPrefix("/auth/register"),
    verifySession: setBaseUrlPrefix("/auth/verify-session"),
    verifyEmail: setBaseUrlPrefix("/auth/verify-email"),
    sendEmail:setBaseUrlPrefix("/auth/send/verification-email"),
    forgetPassword:setBaseUrlPrefix("/auth/forgot-password"),
    updateProfile: setBaseUrlPrefix("/auth/customer"),
    resetPasswordToken:setBaseUrlPrefix("/auth/reset-password")
  },
  booking: {
    getCustomerBooking: setBaseUrlPrefix("/bookings/allBookingCustomer"),
    getAttachment: setBaseUrlPrefix("/bookings/attachment"),
    getQrCode: setBaseUrlPrefix("/bookings/qrCode"),
    verifyQrCode: setBaseUrlPrefix("/bookings/verifyQrCode"),
  },
  language: {
    getLanguageList: setBaseUrlPrefix("/feature/languages"),
    addLanguage: setBaseUrlPrefix("/feature/addLanguage"),
    updateLanguage: setBaseUrlPrefix("/feature/updateLanguage"),
    deleteLanguage: setBaseUrlPrefix("/feature/deleteLanguage"),
  },
  pageHeadings: {
    getPageHeadings: setBaseUrlPrefix("/feature/pageheading"),
    addPageHeadings: setBaseUrlPrefix("/feature/addPageheading"),
    updatePageHeadings: setBaseUrlPrefix("/feature/updatePageheading"),
  },
  event: {
    getEventList: setBaseUrlPrefix("/org/getEvent"),
    getEventImage: setBaseUrlPrefix("/org/getEventImages"),
    updateEvent: setBaseUrlPrefix("/org/updateEvent"),
    updateStatus: setBaseUrlPrefix("/org/updateStatus"),
    deleteEvent: setBaseUrlPrefix("/org/deleteEvent"),
  },
  eventTickets: {
    addEventTicket: setBaseUrlPrefix("/org/addTicket"),
    updateEventTicket: setBaseUrlPrefix("/org/updateTicket"),
    ticketList: setBaseUrlPrefix("/org/getTicketList"),
    deleteTicket: setBaseUrlPrefix("/org/deleteTicket"),
  },
  movieTickets: {
    ticketList: setBaseUrlPrefix("/org/getMovieTicketList"),
    addMovieTicket: setBaseUrlPrefix("/org/addMovieTicket"),
    updateMovieTicket: setBaseUrlPrefix("/org/updateMovieTicket"),
    deleteMovieTicket: setBaseUrlPrefix("/org/deleteMovieTicket"),
  },
  movie: {
    movieId: setBaseUrlPrefix("/admin/movieData"),
    addMovie: setBaseUrlPrefix("/org/addMovie"),
    updateMovieStatus: setBaseUrlPrefix("/org/updateMovieStatus"),
    addTheater: setBaseUrlPrefix("/org/addTheatre"),
    getTheaters: setBaseUrlPrefix("/org/listTheaters"),
    updateMovie: setBaseUrlPrefix("/org/updateMovie"),
    deleteMovie: setBaseUrlPrefix("/org/deleteMovie"),
    deleteTheatre: setBaseUrlPrefix("/org/deleteTheatre"),
  },
  eventManagement: {
    getEventList: setBaseUrlPrefix("/admin/eventList"),
  },
  movieAdmin: {
    getMovieList: setBaseUrlPrefix("/admin/moviesList"),
  },
  blogCategories: {
    getBlogCategoriesList: setBaseUrlPrefix("/blog/blogCategoryList"),
    addBlogCategory: setBaseUrlPrefix("/blog/addBlogCategory"),
    updateBlogCategory: setBaseUrlPrefix("/blog/updateBlogCategory"),
    deleteBlogCategory: setBaseUrlPrefix("/blog/deleteBlogCategory"),
  },
  blog: {
    getBlogList: setBaseUrlPrefix("/blog/blogList"),
    addBlog: setBaseUrlPrefix("/blog/addBlog"),
    updateBlog: setBaseUrlPrefix("/blog/updateBlog"),
    deleteBlog: setBaseUrlPrefix("/blog/deleteBlog"),
  },
  faq: {
    getFaqList: setBaseUrlPrefix("/faq/faqList"),
    addFaq: setBaseUrlPrefix("/faq/addFaq"),
    updateFaq: setBaseUrlPrefix("/faq/updateFaq"),
    deleteFaq: setBaseUrlPrefix("/faq/deleteFaq"),
  },
  header: {
    getHeader: setBaseUrlPrefix("/feature/getHeader"),
    addHeader: setBaseUrlPrefix("/feature/addHeader"),
    deleteHeader: setBaseUrlPrefix("/feature/deleteHeader"),
  },
  aboutUs: {
    getAboutUs: setBaseUrlPrefix("/feature/aboutUs"),
    addAboutUs: setBaseUrlPrefix("/feature/addAboutUs"),
    updateAboutUs: setBaseUrlPrefix("/feature/updateAboutUs"),
    deleteAboutUs: setBaseUrlPrefix("/feature/deleteAboutUs"),
  },
  testimonialSection: {
    getTestimonial: setBaseUrlPrefix("/feature/testimonialSection"),
    addTestimonial: setBaseUrlPrefix("/feature/addTestimonialSection"),
    updateTestimonial: setBaseUrlPrefix("/feature/updateTestimonialSection"),
    deleteTestimonial: setBaseUrlPrefix("/feature/deleteTestimonialSection"),
  },
  transactions: {
    getOrgTransactions: setBaseUrlPrefix("/transaction/getOrgTransactions"),
    getAdminTransactions: setBaseUrlPrefix("/transaction/transactions"),
  },
  coupons: {
    getEventCoupon: setBaseUrlPrefix("/feature/coupons"),
    getCoupons: setBaseUrlPrefix("/feature/couponList"),
    getAllEventsMovies: setBaseUrlPrefix("/admin/allEventsAndMovies"),
    addCoupon: setBaseUrlPrefix("/feature/addCoupon"),
    getCouponByCode: setBaseUrlPrefix("/feature/coupon"),
    deleteCoupon: setBaseUrlPrefix("/feature/deleteCoupon"),
  },
  emailTemplates: {
    listTemplates: setBaseUrlPrefix("/feature/emailTemplates"),
    updateTemplates: setBaseUrlPrefix("/feature/updateEmailTemplate"),
  },
  withDrawal:{
    connectAccount:setBaseUrlPrefix("/transaction/connectAccountLink"),
    checkStatus:setBaseUrlPrefix("/transaction/checkStatus"),
    requestWithdrawal:setBaseUrlPrefix("/transaction/requestWithdrawal"),
    withdrawalList:setBaseUrlPrefix("/transaction/withdrawals"),
    updateStatus:setBaseUrlPrefix("/transaction/updateWithdrawal")
  },
  contactUs:{
      mailToAdmin:setBaseUrlPrefix("/feature/mailToAdmin"),
      addSubscriber:setBaseUrlPrefix("/feature/addSubscriber")
  }
};

export default apiPathConstants;
