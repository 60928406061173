import { lazy, Suspense } from "react";
import AdminHeaders from "../component/Admin/AdminHeaders";
import AdminEditAdmin from "../component/Admin/AdminEditAdmin.jsx";

const AdminDash = lazy(() => import("../component/Admin/AdminDashBoard"));
const AdminTransactions = lazy(() =>
  import("../component/Admin/AdminTranscation")
);
const AdminEventCategories = lazy(() =>
  import("../component/Admin/EventCategories")
);
const EventBookingHistory = lazy(()=>
 import("../component/Admin/EventBookingHistory.jsx")
)
const MovieBookingHistory = lazy(()=>
 import("../component/Admin/MovieBookingHistory.jsx")
)
const AllAdmins= lazy(()=>
import("../component/Admin/AdminAllAdmins.jsx"))
const AdminDetails = lazy(()=> import("../component/Admin/AdminDetails.jsx")) 
const CustomerDetails = lazy(()=> import("../component/Admin/AdminCustomerDetails.jsx")) 
const AddAdmin = lazy(()=>
import("../component/Admin/AdminAddAdmin.jsx"))
const EditAdmin = lazy(()=> import("../component/Admin/AdminEditAdmin.jsx"))
const EditCustomer = lazy(()=> import("../component/Admin/AdminEditCustomer.jsx"))
const AdminCompletedBooking = lazy(() =>
  import("../component/Admin/AdminCompletedBooking")
);
const AdminHeader = lazy(()=>
import("../component/Admin/AdminHeaders"))
const SidebarAdmin = lazy(() => import("../component/Admin/SideBarAdmin"));
const AdminVenueEvents = lazy(() =>
  import("../component/Admin/AdminVenueEvents")
);
const OrganizerDetails = lazy(() => import("../component/OrganizerDetails"));
const AdminOnlineEvents = lazy(() =>
  import("../component/Admin/AdminOnlineEvents")
);
const AdminMovies = lazy(() =>
  import("../component/Admin/movie-management/MovieManagement")
);
const updateMovies = lazy(() =>
  import("../component/Admin/movie-management/UpdateMovie")
);
const AddMovies = lazy(() =>
  import("../component/Admin/movie-management/AddMovie")
);


const movieHistory = lazy(() =>
  import("../component/Admin/movie-management/Moviehistory.js")
);
const theater = lazy(()=>
import('../component/Admin/movie-management/AllotTheater.jsx')
)
const AddTheater = lazy(()=>
  import('../component/Admin/movie-management/AddTheater.jsx')
  )
const AdminUpdateEvent = lazy(() => import("../component/Admin/UpdateEvent"));
const WithdrawPaymentMethods = lazy(() =>
  import("../component/Admin/WithdrawPaymentMethods")
);
const AdminRegisteredOrganizer = lazy(() =>
  import("../component/Admin/AdminRegisteredOrganizer")
);
const AdminAddOrganizer = lazy(() =>
  import("../component/Admin/AdminAddOrganizer")
);
const AdminRegisteredCustomer = lazy(() =>
  import("../component/Admin/AdminRegisteredCustomer")
);
const AdminAddCustomer = lazy(() =>
  import("../component/Admin/AdminAddCustomer")
);
const AdminAnnouncePopups = lazy(() =>
  import("../component/Admin/AdminAnnouncePopups")
);
const AdminSubscribers = lazy(() =>
  import("../component/Admin/AdminSubscribers")
);
const AdminMailSubscribers = lazy(() =>
  import("../component/Admin/AdminMailSubscribers")
);
const AdminInviteSubscribers = lazy(() =>
  import("../component/Admin/AdminInviteSubscribers")
);
const AdminLanguageKeyword = lazy(() =>
  import("../component/Admin/AdminLanguageKeyword")
);
const AdminLanguageManagement = lazy(() =>
  import("../component/Admin/AdminLanguageManagement")
);
const AdminPwaScannerSetting = lazy(() =>
  import("../component/Admin/AdminPwaScannerSetting")
);
const AdminTickets = lazy(() => import("../component/Admin/Tickets"));
const AddTicket = lazy(() => import("../component/AdminTickets/PricingTabs"));
const updateTicket = lazy(() =>
  import("../component/AdminTickets/TicketUpdate")
);
const AdminMovieTickets = lazy(() => import("../component/Admin/movie-management/MovieTickets.jsx"));
const AdminAddTheatre = lazy(() => import("../component/Admin/movie-management/AddTheater.jsx"))
const AddMovieTicket = lazy(() => import("../component/AdminMovieTickets/PricingTabs"));
const updateMovieTicket = lazy(() =>
  import("../component/AdminMovieTickets/TicketUpdate")
);
const AdminOnlineGateWay = lazy(() =>
  import("../component/Admin/AdminOnlineGateWay")
);
const AdminOfflineGateway = lazy(() =>
  import("../component/Admin/AdminOfflineGateway")
);
const AdminSupportAllTickets = lazy(() =>
  import("../component/Admin/AdminSupportAllTickets")
);

const EditOrganizer = lazy(() =>
  import("../component/Admin/AdminEditOrganizer.jsx")
);
const DetailOrganizer = lazy(() =>
  import("../component/Admin/AdminOrganizerDetails.jsx")
);
const AdminSupportPendingTickets = lazy(() =>
  import("../component/Admin/AdminSupportPendingTickets")
);
const AdminSupportCloseTickets = lazy(() =>
  import("../component/Admin/AdminSupportCloseTickets")
);
const AdminSupportOpenTickets = lazy(() =>
  import("../component/Admin/AdminSupportOpenTickets")
);
const AdminBlogManagementCategories = lazy(() =>
  import("../component/Admin/AdminBlogManagementCategories")
);
const AdminBlogManagementBlogs = lazy(() =>
  import("../component/Admin/AdminBlogManagementBlogs")
);
const AdminStripeTransactions= lazy(()=> import('../component/Admin/Transactions.jsx'))
const AdminCoupon = lazy(() => import("../component/Admin/AdminCoupon"));
const AdminReports = lazy(() => import("../component/Admin/AdminReports"));
const AdminTaxCommission = lazy(() =>
  import("../component/Admin/AdminTaxCommission")
);
const AdminOrganizerSettings = lazy(() =>
  import("../component/Admin/AdminOrganizerSettings")
);
const AdminSupportTicketsSettings = lazy(() =>
  import("../component/Admin/AdminSupportTicketsSettings")
);
const updateCustomer = lazy(() =>
  import("../component/Admin/AdminUpdateCustomer")
);
const AdminPushNotificationSettings = lazy(() =>
  import("../component/Admin/AdminPushNotificationSettings")
);
const AdminPushNotificationSendNoti = lazy(() =>
  import("../component/Admin/AdminPushNotificationSendNoti")
);
const AdminGeneralSettings = lazy(() =>
  import("../component/Admin/AdminGeneralSettings")
);
const AdminSettingsBreadcrumb = lazy(() =>
  import("../component/Admin/AdminSettingsBreadcrumb")
);
const AdminPageHeadingSettings = lazy(() =>
  import("../component/Admin/AdminPageHeadingSettings")
);
const AdminPluginsSettings = lazy(() =>
  import("../component/Admin/AdminPluginsSettings")
);
const AdminSettingSeoInformation = lazy(() =>
  import("../component/Admin/AdminSettingSeoInformation")
);
const AdminSettingsMaintenanceMode = lazy(() =>
  import("../component/Admin/AdminSettingsMaintenanceMode")
);
const AdminSettingCookieAlert = lazy(() =>
  import("../component/Admin/AdminsettingCookieAlert")
);
const AdminSettingsFooterLogo = lazy(() =>
  import("../component/Admin/AdminSettingsFooterlogo")
);
const AdminSocialMediaSetting = lazy(() =>
  import("../component/Admin/AdminSocialMediaSetting")
);
const AdminEmailSettMailFromAdmin = lazy(() =>
  import("../component/Admin/AdminEmailSettMailFromAdmin")
);
const AdminEmailSettMailToAdmin = lazy(() =>
  import("../component/Admin/AdminEmailSettMailToAdmin")
);
const AdminEmailSettMailTemplates = lazy(() =>
  import("../component/Admin/AdminEmailSettMailTemplates")
);
const AdminEditProfile = lazy(() =>
  import("../component/Admin/AdminEditProfile")
);
const AdminChangePassword = lazy(() =>
  import("../component/Admin/AdminChangePassword")
);
const AdminAddOnlineEvent = lazy(() =>
  import("../component/Admin/AdminAddOnlineEvent")
);
const AdminAddVenueEvent = lazy(() =>
  import("../component/Admin/AdminAddVenueEvent")
);
const AdminSelectPopUp = lazy(() =>
  import("../component/Admin/AdminSelectPopUp")
);
const EventManagementAdmin = lazy(() =>
  import("../component/Admin/EventManagementAdmin")
);
const AdminRejectedBooking = lazy(() =>
  import("../component/Admin/AdminRejectedBooking")
);
const EventBookingAdmin = lazy(() =>
  import("../component/Admin/EventBookingAdmin")
);
const MovieBooking = lazy(()=>
 import('../component/Admin/MovieBookings')
);
const MovieCompletedBooking = lazy(()=>
 import("../component/Admin/MovieCompleteBookings.jsx")
)
const MoviePendingBooking = lazy(()=>
  import("../component/Admin/MoviePendingBookings.jsx")
 )
 const MovieRejectedBooking = lazy(()=>
  import("../component/Admin/MovieRejectedBookings.jsx")
 )
const AdminWithdrawRequest = lazy(() =>
  import("../component/Admin/AdminWithdrawRequest")
);
const EventManageAllEvents = lazy(() =>
  import("../component/Admin/EventManageAllEvents")
);
const AdminRoleAndPermission = lazy(() => import("../component/Admin/AdminRoleAndPermission.jsx"));

const AdminEditPermissions = lazy(() => import("../component/Admin/AdminPermissionPage.jsx"));
const AdminMovieGenres= lazy(()=>
import("../component/Admin/AdminMovieGenres.jsx"))
const AdminAddCoupon =lazy(()=>
import("../component/Admin/AddCoupons.jsx"))
const AdminGuestCheckout = lazy(() =>
  import("../component/Admin/AdminGuestCheckout")
);
const AddEventAdmin = lazy(() => import("../component/Admin/AddEventAdmin"));
const AdminPendingBooking = lazy(() =>
  import("../component/Admin/AdminPendingBooking")
);
const AdminFaq = lazy(() => import("../component/Admin/AdminFaq"));
const AdminAddBlog = lazy(() => import("../component/Admin/AdminAddBlog"));
const AdminAddHeader = lazy(()=> import("../component/Admin/AdminAddHeader.jsx"))

const renderRoute = (Component) => (

  <Suspense
    fallback={
      <div className="flex space-x-2 justify-center items-center bg-white h-screen dark:invert">
        <span className="sr-only">Loading...</span>
        <div className="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]"></div>
        <div className="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]"></div>
        <div className="h-8 w-8 bg-black rounded-full animate-bounce"></div>
      </div>
    }
  >
    <Component />
  </Suspense>
);

const adminNavigationRoutes = [
  { path: "", element: renderRoute(AdminDash), name: "Dashboard" },
  {
    path: "transcations",
    element: renderRoute(AdminTransactions),
    name: "Transactions",
  },
  { path: "sidebarAdmin", element: renderRoute(SidebarAdmin), name: "Sidebar" },
  {
    path: "event-management",
    element: renderRoute(EventManagementAdmin),
    name: "Event Management",
  },
  {
    path: "role-permissions",
    element: renderRoute(AdminRoleAndPermission),
    name: "Roles & Permissions",
  },{
    path:'stripe-transactions',
    element:renderRoute(AdminStripeTransactions),
    name:"Stripe Transactions"
  },
  {
    path: "role-permissions/edit-permission",
    element: renderRoute(AdminEditPermissions),
    name: "Edit Permissions",
  },
  {
    path: "event-booking/all-bookings",
    element: renderRoute(EventBookingAdmin),
    name: "All Bookings",
  },
  {
    path: "movie-booking/all-bookings",
    element: renderRoute(MovieBooking),
    name: "All Movie  Bookings",
  },
  {
    path: "movie-booking/completed-bookings",
    element: renderRoute(MovieCompletedBooking),
    name: "All Movie completed Bookings",
  },
  {
    path: "movie-booking/pending-bookings",
    element: renderRoute(MoviePendingBooking),
    name: "All Movie Pending Bookings",
  },
  {
    path: "movie-booking/rejected-bookings",
    element: renderRoute(MovieRejectedBooking),
    name: "All Movie Rejected Bookings",
  },
  {
    path: "event-management/event-categories",
    element: renderRoute(AdminEventCategories),
    name: "Event Categories",
  },
  {
    path:'movie-management/add-movie',
    element:renderRoute(AddMovies),
    name:"Add Movies"
  },
  {
    path:'movie-management/all-movies',
    element:renderRoute(AdminMovies),
    name:"All Movies"
  },
  {
    path:'movie-management/update-movie',
    element:renderRoute(updateMovies),
    name:"Update Movie"
  },
  {
    path:'movie-management/theatres',
    element:renderRoute(theater),
    name:"Theatres"
  },
  {
    path:'movie-management/add-theatre',
    element:renderRoute(AdminAddTheatre),
    name:"Add Theatre"
  },
  {
    path: "movie-management/ticket-manage",
    element: renderRoute(AdminMovieTickets),
    name: "Movie Ticket Management",
  },
  {
    path: "movie-management/addTicket",
    element: renderRoute(AddMovieTicket),
    name: "Add Movie Ticket Management",
  },
  {
    path: "movie-management/updateTicket",
    element: renderRoute(updateMovieTicket),
    name: "update Movie Ticket Management",
  },
  {
    path: "event-booking/completed-booking",
    element: renderRoute(AdminCompletedBooking),
    name: "Completed Bookings",
  },
  {
    path: "withdraw-method/payment",
    element: renderRoute(WithdrawPaymentMethods),
    name: "Withdraw Payment Methods",
  },
  {
    path: "event-booking/pending-booking",
    element: renderRoute(AdminPendingBooking),
    name: "Pending Bookings",
  },
  {
    path: "event-booking/rejected-booking",
    element: renderRoute(AdminRejectedBooking),
    name: "Rejected Bookings",
  },
  {
    path: "event-booking/settings/guest-checkout",
    element: renderRoute(AdminGuestCheckout),
    name: "Guest Checkout",
  },
  {
    path: "event-management/all-events",
    element: renderRoute(EventManageAllEvents),
    name: "All Events",
  },
  {
    path: "event-management/venue-events",
    element: renderRoute(AdminVenueEvents),
    name: "Venue Events",
  },
  {
    path: "event-management/ticket-manage",
    element: renderRoute(AdminTickets),
    name: "Ticket Management",
  },
  {
    path: "event-management/addTicket",
    element: renderRoute(AddTicket),
    name: "Add Ticket Management",
  },
  {
    path: 'movie-management/movie-genres',
    element: renderRoute(AdminMovieGenres),
    name: "Movies Genres"
  },
  {
    path:"events/bookings",
    element:renderRoute(EventBookingHistory),
    name:"Booking History"
  },
  {
    path:"movies/bookings",
    element:renderRoute(MovieBookingHistory),
    name:"Movie Booking History"
  },
  {
    path: "event-management/updateTicket",
    element: renderRoute(updateTicket),
    name: "update Ticket Management",
  },
  {
    path: "event-management/online-events",
    element: renderRoute(AdminOnlineEvents),
    name: "Online Events",
  },
  {
    path: "withdraw-method/request",
    element: renderRoute(AdminWithdrawRequest),
    name: "Withdraw Requests",
  },
  {
    path: "organizer-management/registered-organizer",
    element: renderRoute(AdminRegisteredOrganizer),
    name: "Registered Organizers",
  },
  {
    path: "organizer-management/add-organizer",
    element: renderRoute(AdminAddOrganizer),
    name: "Add Organizer",
  },
  {
    path: "event-management/updateEvent",
    element: renderRoute(AdminUpdateEvent),
    name: "Update Event",
  },
  {
    path: "event-management/add-event",
    element: renderRoute(AddEventAdmin),
    name: "Add Event",
  },
  {
    path: "customer-management/registered-customer",
    element: renderRoute(AdminRegisteredCustomer),
    name: "Registered Customers",
  },
  {
    path: "customer-management/add-customer",
    element: renderRoute(AdminAddCustomer),
    name: "Add Customer",
  },
  {
    path: "customer-management/registered-customer/details",
    element: renderRoute(CustomerDetails),
    name: "Customer Detail",
  },
  {
    path: "customer-management/update-customer",
    element: renderRoute(updateCustomer),
    name: "Update Customer",
  },
  { path: "faqs", element: renderRoute(AdminFaq), name: "FAQs" },
  {
    path: "announce-popups",
    element: renderRoute(AdminAnnouncePopups),
    name: "Announce Popups",
  },
  {
    path: "headers",
    element: renderRoute(AdminHeaders),
    name: "Announce Headers",
  },
  {
    path: "add-header",
    element: renderRoute(AdminAddHeader),
    name: "Announce Headers",
  },
  {
    path: "organizer-management/registered-organizer/edit-organizer",
    element: renderRoute(EditOrganizer),
    name: "Edit Registered Organizers",
  },
  {
    path: "organizer-management/registered-organizer/detail",
    element: renderRoute(DetailOrganizer),
    name: "Detail Registered Organizers",
  },
  {
    path: "subscribers",
    element: renderRoute(AdminSubscribers),
    name: "Subscribers",
  },
  {
    path: "mail-to-subscribers",
    element: renderRoute(AdminMailSubscribers),
    name: "Mail to Subscribers",
  },
  {
    path: "invite-to-subscribers",
    element: renderRoute(AdminInviteSubscribers),
    name: "Invite to Subscribers",
  },
  {
    path: "edit-language-keyword",
    element: renderRoute(AdminLanguageKeyword),
    name: "Edit Language Keyword",
  },
  {
    path: "language-management",
    element: renderRoute(AdminLanguageManagement),
    name: "Language Management",
  },
  {
    path: "pwa-settings/pwa-scanner-settings",
    element: renderRoute(AdminPwaScannerSetting),
    name: "PWA Scanner Settings",
  },
  {
    path: "payment-gateway/online-gateway",
    element: renderRoute(AdminOnlineGateWay),
    name: "Online Gateway",
  },
  {
    path: "payment-gateway/offline-gateway",
    element: renderRoute(AdminOfflineGateway),
    name: "Offline Gateway",
  },
  {
    path: "support-tickets/all-tickets",
    element: renderRoute(AdminSupportAllTickets),
    name: "All Tickets",
  },
  {
    path: "support-tickets/pending-tickets",
    element: renderRoute(AdminSupportPendingTickets),
    name: "Pending Tickets",
  },
  {
    path: "support-tickets/open-tickets",
    element: renderRoute(AdminSupportOpenTickets),
    name: "Open Tickets",
  },
  {
    path: "support-tickets/close-tickets",
    element: renderRoute(AdminSupportCloseTickets),
    name: "Close Tickets",
  },
  {
    path: "blog-management/categories",
    element: renderRoute(AdminBlogManagementCategories),
    name: "Blog Categories",
  },
  {
    path: "blog-management/add-blog",
    element: renderRoute(AdminAddBlog),
    name: "Add Blog",
  },
  {
    path: "blog-management/all-blogs",
    element: renderRoute(AdminBlogManagementBlogs),
    name: "All Blogs",
  },
  {
    path: "event-booking/settings/coupon",
    element: renderRoute(AdminCoupon),
    name: "Coupon",
  },
  {
    path: "event-booking/addCoupon",
    element: renderRoute(AdminAddCoupon),
    name: "Add Coupon",
  },
  {
    path: "event-booking/report",
    element: renderRoute(AdminReports),
    name: "Reports",
  },
  {
    path: "event-booking/settings/tax-comission",
    element: renderRoute(AdminTaxCommission),
    name: "Tax & Commission",
  },
  {
    path: "organizer-management/settings",
    element: renderRoute(AdminOrganizerSettings),
    name: "Organizer Settings",
  },
  {
    path: "organizer-management/organizerDetails",
    element: renderRoute(OrganizerDetails),
    name: "Organizer Details",
  },
  {
    path: "support-tickets/settings",
    element: renderRoute(AdminSupportTicketsSettings),
    name: "Support Tickets Settings",
  },
  {
    path: "push-notification/settings",
    element: renderRoute(AdminPushNotificationSettings),
    name: "Push Notification Settings",
  },
  {
    path:"admin-management/registered-admin",
    element:renderRoute(AllAdmins),
    name:"All Admins"
  },
  {
    path:"admin-management/registered-admin/detail",
    element:renderRoute(AdminDetails),
    name:"Admin Detail"
  },
  {
    path:"admin-management/registered-admin/edit-admin",
    element:renderRoute(AdminEditAdmin),
    name:"Admin Edit Detail"
  },
  {
    path:"admin-management/add-admin",
    element:renderRoute(AddAdmin),
    name:"Add Admins"
  },
  {
    path: "push-notification/notification",
    element: renderRoute(AdminPushNotificationSendNoti),
    name: "Send Notification",
  },
  {
    path: "basic-settings/general-settings",
    element: renderRoute(AdminGeneralSettings),
    name: "General Settings",
  },
  {
    path: "basic-settings/breadcrumb",
    element: renderRoute(AdminSettingsBreadcrumb),
    name: "Breadcrumb",
  },
  {
    path: "basic-settings/page-headings",
    element: renderRoute(AdminPageHeadingSettings),
    name: "Page Headings",
  },
  {
    path: "basic-settings/plugins",
    element: renderRoute(AdminPluginsSettings),
    name: "Plugins",
  },
  {
    path: "basic-settings/seo-information",
    element: renderRoute(AdminSettingSeoInformation),
    name: "SEO Information",
  },
  {
    path: "basic-settings/maintenance-mode",
    element: renderRoute(AdminSettingsMaintenanceMode),
    name: "Maintenance Mode",
  },
  {
    path: "basic-settings/cookie-alert",
    element: renderRoute(AdminSettingCookieAlert),
    name: "Cookie Alert",
  },
  {
    path: "basic-settings/footer-logo",
    element: renderRoute(AdminSettingsFooterLogo),
    name: "Footer Logo",
  },
  {
    path: "basic-settings/social-media",
    element: renderRoute(AdminSocialMediaSetting),
    name: "Social Media",
  },
  {
    path: "basic-settings/email-settings/mail-from-admin",
    element: renderRoute(AdminEmailSettMailFromAdmin),
    name: "Mail from Admin",
  },
  {
    path: "basic-settings/email-settings/mail-to-admin",
    element: renderRoute(AdminEmailSettMailToAdmin),
    name: "Mail to Admin",
  },
  {
    path: "basic-settings/email-settings/mail-templates",
    element: renderRoute(AdminEmailSettMailTemplates),
    name: "Mail Templates",
  },
  {
    path: "profile-settings/edit-profile",
    element: renderRoute(AdminEditProfile),
    name: "Edit Profile",
  },
  {
    path: "profile-settings/change-password",
    element: renderRoute(AdminChangePassword),
    name: "Change Password",
  },
  {
    path: "event-management/add-event/online-event",
    element: renderRoute(AdminAddOnlineEvent),
    name: "Add Online Event",
  },
  {
    path: "event-management/add-event/venue-event",
    element: renderRoute(AdminAddVenueEvent),
    name: "Add Venue Event",
  },
  {
    path: "announcement-popups/create-popup",
    element: renderRoute(AdminSelectPopUp),
    name: "Create Popup",
  },
];

export default adminNavigationRoutes;
