import apiPath from "../constants/api-path.constant";

import axios from "../configs/axios-instances";

const authApi = {
  handleLogin: ({ payload, success, error }) => {
    const {
      auth: { login },
    } = apiPath;

    axios.postRequest({ path: login, payload, success, error });
  },

  handleLogout: ({ success, error }) => {
    const {
      auth: { logout },
    } = apiPath;
    axios.postRequest({ path: logout, success, error });
  },
  handleRegister: ({ payload, success, error }) => {
    const {
      auth: { register },
    } = apiPath;
    axios.postRequest({ path: register, payload, success, error });
  },
  verifySession: ({ payload, success, error, final }) => {
    const {
      auth: { verifySession },
    } = apiPath;
    axios.postRequest({ path: verifySession, payload, success, error, final });
  },

  sendVerifyEmail: ({ payload, success, error, final }) => {
    const {
      auth: { sendEmail },
    } = apiPath;
    axios.postRequest({ path: sendEmail, payload, success, error, final });
  },
  forgetPassword: ({ payload, success, error, final }) => {
    const {
      auth: { forgetPassword },
    } = apiPath;
    axios.postRequest({ path: forgetPassword, payload, success, error, final });
  },
  handleResetPassword: ({ payload, password_reset_token, success, error }) => {
    const {
      auth: { resetPasswordToken },
    } = apiPath;
    const pathWithParams = `${resetPasswordToken}/${password_reset_token}`;
    axios.postRequest({ path: pathWithParams, payload, success, error });
  },
  handleVerifyEmail: ({ payload, success, error }) => {
    const {
      auth: { verifyEmail },
    } = apiPath;
    const { verificationToken } = payload;
    const pathWithParams = `${verifyEmail}/${verificationToken}`;

    axios.postRequest({ path: pathWithParams, payload, success, error });
  },
  handleResetPassword: ({ payload, password_reset_token, success, error }) => {
    const {
      auth: { resetPasswordToken },
    } = apiPath;
    const pathWithParams = `${resetPasswordToken}/${password_reset_token}`;
    axios.postRequest({ path: pathWithParams, payload, success, error });
  },
  handleUpdate: ({ payload, success, error, final }) => {
    const {
      auth: { updateProfile },
    } = apiPath;
    axios.putRequest({ path: updateProfile, payload, success, error, final });
  },
};

export default authApi;
