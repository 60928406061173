import apiAdminPath from "../../constants/admin/auth-org-path.contant";

import axios from "../../configs/axios-instances"; 


const authApiAdmin = {

    handleLoginAdmin: ({ payload, success, error}) => {
        const { authAdmin : { loginAdmin } } = apiAdminPath;
        axios.postRequest({ path : loginAdmin , payload, success, error});
    },

    handleLogoutAdmin: ({ success, error}) => {
        const { authAdmin: { logoutAdmin } } = apiAdminPath;
        axios.postRequest({ path : logoutAdmin , success, error});
    },

    handleRegisterAdmin: ({ payload, success, error}) => {
        const { authAdmin: { registerAdmin } } = apiAdminPath;
        axios.postRequest({ path : registerAdmin , payload, success, error});
    },

    handleUpdateProfileAdmin: ({ payload, success, error, final }) => {
        const { authAdmin: { updateProfileAdmin } } = apiAdminPath;
        axios.putRequest({ path : updateProfileAdmin , payload, success, error, final });
    },

    handleVerifyEmailAdmin: ({ payload, success, error }) => {
        const {  authAdmin: { verifyEmailAdmin }  } = apiAdminPath;
        const { verificationToken } = payload;
        const pathWithParams=`${verifyEmailAdmin}/${verificationToken}`;
        axios.postRequest({ path: pathWithParams, payload, success, error });
    },

    handleForgotPasswordAdmin: ({ payload, success, error}) => {
        const { authAdmin: { forgotPasswordAdmin } } = apiAdminPath;
        axios.postRequest({ path : forgotPasswordAdmin , payload, success, error});
    },

    handleResetPasswordAdmin: ({ payload, success, error }) => {
        const {  authAdmin: { resetPasswordTokenAdmin }  } = apiAdminPath;
        const { verificationToken } = payload;
        const pathWithParams=`${resetPasswordTokenAdmin}/${verificationToken}`;
        axios.postRequest({ path: pathWithParams, payload, success, error });
    },

    verifySessionAdmin:  ({ payload, success, error, final }) => {
        const { authAdmin : { verifySessionAdmin } } = apiAdminPath;
        axios.postRequest({ path: verifySessionAdmin, payload, success, error, final });
    },
    listRoles:  ({  success, error, final }) => {
        const { authAdmin : { listOfRoleAdmin } } = apiAdminPath;
        axios.getRequest({ path: listOfRoleAdmin, success, error, final });
    },
    updateRoles:({payload,success,error})=>{
        const { authAdmin : { updateRoleAdmin } } = apiAdminPath;
        axios.putRequest({path:updateRoleAdmin,payload,success,error})
    },
    addRoles:({payload,success,error})=>{
        const { authAdmin : { addRoleAdmin } } = apiAdminPath;
        axios.postRequest({path:addRoleAdmin,payload,success,error})
    },
    deleteRole:({payload,success,error})=>{
        const { authAdmin : { deleteRoleAdmin } } = apiAdminPath;
        axios.deleteRequest({path:deleteRoleAdmin,payload,success,error})
    }
};

export default authApiAdmin;
