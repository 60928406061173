import React, { useEffect, useState } from "react";
import SidebarAdmin from "./SideBarAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FaHome } from "react-icons/fa";
import { Link ,useNavigate} from "react-router-dom";
import adminManagementAdminApi from "../../apis/adminApis/adminManagment.api";
import authApiAdmin from "../../apis/adminApis/authAdmin.api";
import { toast } from "react-toastify";

const AdminEditAdmin = () => {
  //States

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
const navigate=useNavigate()
  const [isOpen, setIsOpen] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [photo, setPhoto] = useState(null);
  const [roles, setRoles] = useState(null);
  const [role_id, setRoleId] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [details, setDetails] = useState();
  const [username, setUsername] = useState("");
  const [previewImage,setPreviewImage]=useState(null);
  const [name, setName] = useState("");
  const [gateway_fee, setGatewayFee] = useState(0);
  
  const handleRoleChange = (event) => {
    const selectedRoleId = event.target.value;
    setRoleId(selectedRoleId);
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl)

      setPhoto(file);
        
      
    }
  };

  //Functions
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const toggleSection = () => {
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    authApiAdmin.listRoles({
      success: (res) => {
        let roles = res.data.data;

        const extractedRoles = roles.map((role) => ({
          id: role.id,
          name: role.name,
        }));

        setRoles(extractedRoles);
      },
      error: (err) => {
        toast.error("Error in Server", {
          position: "top-center",
          autoClose: 2000,
        });
      },
    });

    adminManagementAdminApi.getAdminList({
      payload: {
        id,
      },
      success: (res) => {
        let admin = res.data.data.admins[0];

        setDetails(admin?.details);
        setGatewayFee(admin?.gateway_fee);
        setPreviewImage(admin?.image);
        setEmail(admin?.email);
        setName(admin?.name);
        setPhone(admin?.phone);
        
        setUsername(admin?.username);
        setRoleId(admin?.role_id);
      },
      error: (err) => {},
    });
  }, []);
  const submitHandler = (e) => {
    e.preventDefault();

    const enteredData = {
      id,
      email,
      name,
      photo,
      role_id,
      details,
      gateway_fee
    };


    const formData = new FormData();

    for (const [key, value] of Object.entries(enteredData)) {
        if (value !== null && value !== undefined) { 
          formData.append(key, value);
        }
      }

    adminManagementAdminApi.updateAdmin({
      payload: formData,
      success: (data) => {
        toast.success("Admin updated successfully.", {
          position: "top-center",
          autoClose: 2000,
        });
        navigate("/admin/customer-management/registered-customer")
      },
      error: (err) => {
        console.log("Error in adding .", err);
        toast.error("Error occurred while updating admin.", {
          position: "top-center",
          autoClose: 2000,
        });
      },
    });
  };

  return (
    <div>
      <div>
        <div className="flex items-center space-x-2 text-sm">
          <h1 className="text-lg font-bold">Edit Admin</h1>
          <span className="border-r h-4 border-black mx-2"></span>
          <div className="flex items-center space-x-2 text-sm">
            <Link to="/admin" className="text-black hover:underline">
              <FaHome className="inline" />
            </Link>
            <span className="text-xl mx-1 md:mx-2">&gt;</span>

            <Link
              to="/admin/admin-management/registered-admin"
              className="text-black hover:underline"
            >
              Admin Management
            </Link>
            <span className="text-xl mx-1 md:mx-2">&gt;</span>

            <Link
              to="/admin/admin-management/registered-admin/edit-admin"
              className="text-black hover:underline"
            >
              Edit Admin
            </Link>
          </div>
        </div>
        <br />
        <div className="max-w-3xl mx-auto">
          {" "}
          {/* Set maximum width */}
          {/* First White Box */}
          <form onSubmit={submitHandler} className="space-y-4">
            <div className="bg-white rounded-md shadow-md p-6 w-full">
              <h2 className="text-2xl font-bold mb-4">Edit Admin</h2>

              <div className="flex flex-col md:flex-row items-center">
                {/* Photo */}
                <center>
                  <div className="mb-4 md:mr-6 md:mb-0 flex-shrink-0 mx-auto">
                    {/* Center photo */}
                    <label className="block mb-2">Photo</label>
                    <div className="w-32 h-32 border border-gray-300 rounded-full flex items-center justify-center overflow-hidden">
                      {previewImage ? (
                        <img
                          src={previewImage}
                          alt="User's Photo"
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <span className="text-gray-400">No Photo</span>
                      )}
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handlePhotoChange}
                      className="mt-2"
                    />
                  </div>
                </center>
                <br />
                {/* Email and Phone */}
                <br />
              </div>
              <div className="flex flex-col md:flex-row flex-grow">
                <br />
                <div className="md:mr-4">
                  <br />

                  <label className="block mb-2" htmlFor="email">
                    Email *
                  </label>
                  <input
                    className="border border-gray-300 rounded-md px-4 py-2 mb-4 md:mb-0 w-full md:w-auto"
                    type="email"
                    id="email"
                    value={email}
                    placeholder="Enter Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <br />

                  <label className="block mb-2" htmlFor="phone">
                    Phone *
                  </label>
                  <input
                    className="border border-gray-300 rounded-md px-4 py-2 w-full"
                    type="tel"
                    id="phone"
                    value={phone}
                    placeholder="Enter Phone"
                    onChange={(e) => setPhone(e.target.value)}
                    
                  />
                </div>
              </div>
              {/* Username and Password */}
              <div className="flex flex-col md:flex-row flex-grow mt-4">
                <div className="md:mr-4">
                  <label className="block mb-2" htmlFor="username">
                    Username *
                  </label>
                  <input
                    className="border border-gray-300 rounded-md px-4 py-2 mb-4 md:mb-0 w-full md:w-auto"
                    type="text"
                    id="username"
                    value={username}
                    placeholder="Enter Username"
                    onChange={(e) => setUsername(e.target.value)}
                    readOnly
                  />
                </div>
                <div>
                <label className="block mt-2 mb-2" htmlFor="gateway">
                  Gateway Fee *
                </label>
                <input
                  className="border border-gray-300 rounded-md px-4 py-2 w-full"
                  type="gateway"
                  id="gateway"
                  value={gateway_fee}
                  onChange={(e) => setGatewayFee(e.target.value)}
                  required
                />
                </div>
              </div>
              <div className="mb-4">
                <label className="block mt-2 mb-2" htmlFor="role_id">
                  Role *
                </label>
                <select
                  id="role_id"
                  value={role_id}
                  onChange={handleRoleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                >
                  <option value="">Select a role</option>
                  {roles?.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>

                
              </div>
            </div>
            {/* Second White Box */}
            <div>
              <h2
                className="text-2xl font-bold mb-4 bg-purple-600 text-white rounded-md p-2 cursor-pointer"
                onClick={toggleSection}
              >
                English Language Default
              </h2>
              {isOpen && (
                <div className="bg-white rounded-md shadow-md p-6 w-full">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="mb-4">
                      <label className="block mb-2" htmlFor="name">
                        Name *
                      </label>
                      <input
                        className="border border-gray-300 rounded-md px-4 py-2 w-full"
                        type="text"
                        id="name"
                        value={name}
                        placeholder="Enter Your Full Name"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block mb-2" htmlFor="details">
                        Details
                      </label>
                      <input
                        className="border border-gray-300 rounded-md px-4 py-2 w-full"
                        type="text"
                        id="details"
                        placeholder="Enter Detail's"
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="mt-8">
                    <center>
                      <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-md transition duration-300 hover:bg-blue-600"
                        type="submit"
                      >
                        Submit
                      </button>
                    </center>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminEditAdmin;
