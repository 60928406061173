import React, { useState } from "react";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useStore } from "../../utils/store";
import { toast } from "react-toastify";
import additionalApi from "../../apis/additional.api";

const Footer = () => {
  const { basicSettings } = useStore();
  const [email, setEmail] = useState();

  
  const handleSubmit = (e) => {
    e.preventDefault();
    additionalApi.addSubscriber({
      payload: {
        email
      },
      success: (res) => {
        toast.success("Thank you for subscribing! You'll receive the latest updates and news.", {
          position: "top-center",
          autoClose: 2000
        });
        setEmail("");
      },
      error: (err) => {
        toast.error(err?.response?.data?.message || "Oops! Something went wrong. Please try again later.", {
          position: "top-center",
          autoClose: 2000
        });
      }
    });
  }

  return (
    <div>
     
      <div className="bg-blue-950">
        <div className="mx-auto p-2 lg:max-w-7xl grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 pt-6 px-4 max-md:px-4">
          <div className="mt-0">
            <img
              loading="lazy"
              srcSet={basicSettings?.footer_logo || Logo}
              alt="TicketVerse Logo"
              className="aspect-[3.49] object-contain object-center w-30 overflow-hidden flex justify-center align-middle items-center"
            />
          </div>
          <div>
            <div className="text-white text-lg font-bold capitalize mb-4">Plan Events</div>
            <div className="text-zinc-100 text-sm flex flex-col font-medium leading-7 mb-6">
              <Link to="/registerOrganizer" className="mb-2">Create Events</Link>
              <Link to="/registerOrganizer">Create Movies</Link>
            </div>
          </div>
          <div>
            <div className="text-white text-lg font-bold capitalize mb-4">Quick Links</div>
            <div className="text-zinc-100 flex flex-col text-sm font-medium leading-7 mb-6">
              <Link to="/about" className="mb-2">Terms & Conditions</Link>
              <Link to="/contact" className="mb-2">Contact Us</Link>
              <Link to="/contact" className="mb-2">Privacy Policy</Link>
            </div>
          </div>
          <div>
            <div className="text-white text-lg font-bold capitalize mb-4">Stay in the loop</div>
            <div className="text-zinc-100 text-sm leading-6 mb-6 max-sm:w-full">
              Join our mailing list to stay in the loop with our newest for Event and concert
            </div>
            <form
              onSubmit={handleSubmit}
              className="flex items-center justify-between py-2 space-x-4 max-md:flex-wrap max-md:space-x-0"
            >
              <input
                className="text-neutral-400 border rounded-full border-none text-xs my-auto p-3 w-full max-md:mb-2"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address.."
                required
              />
              <button
                type="submit"
                className="text-white text-xs tracking-wider whitespace-nowrap justify-center items-stretch shadow-2xl bg-pink-600 self-stretch px-4 py-1 rounded-[50px] max-md:px-5"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>

        {/* <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-12 p-4">
          <div>
            <img src={googleMapsUrl} alt="Location" className="w-32 h-24 rounded-md" />
          </div>
          <div>
            <p className="text-white text-sm">
              <span className="font-bold">Email:</span> {basicSettings?.email_address}
            </p>
            <p className="text-white text-sm">
              <span className="font-bold">Phone:</span> {basicSettings?.contact_number}
            </p>
          </div>
        </div> */}

        <div className="bg-slate-600 self-stretch h-px mb-6 w-full" />
        <div className="text-white text-center text-sm pb-4 leading-6 w-full">
          Copyright © {new Date().getFullYear()} TicketVerse
        </div>
      </div>
    </div>
  );
};

export default Footer;