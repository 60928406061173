import { lazy,Suspense } from "react";
import ProfilePage from "../pages/OrganizerPage";

const Home = lazy(() => import("../pages/home/Home"));
const About = lazy(() => import("../pages/about/About"));
const Contact = lazy(() => import("../pages/contact/Contact"));
const List = lazy(() => import("../pages/eventlist/eventlist"));
const MoviesList = lazy(() => import("../pages/movieslist/Movieslist"));
const venueList = lazy(() => import("../pages/venuelist/Venuelist"));


const AdminLogin = lazy(() => import("../component/Admin/AuthAdmin/AdminLogin"));
const ForgetPassword = lazy(() => import("../component/register/ForgetPassword"))
const ForgetPasswordOrg = lazy(() => import("../component/register/ForgetPasswordOrg"))
const RestPassword = lazy(() => import("../component/register/RestPassword"))
const RestPasswordOrg = lazy(() => import("../component/register/RestPasswordOrg"))
const TransactionPDF = lazy(() => import("../component/Admin/TransactionPDF"));
const VerifyEmailOrganizer = lazy(() => import("../component/register/VerifyOrgEmail"))
const Navbar = lazy(() => import("../component/navbar/Navbar"));
const Footer = lazy(()=> import("../component/footer/Footer"));
const Register = lazy(() => import("../component/register/Register"));

const VerifyEmail=lazy(()=> import("../component/register/VerifyEmail"))
const RegisterOrganizer = lazy(() =>
  import("../component/register/RegisterOrganizer")
);

const Login = lazy(() => import("../component/login/Login"));
const VendorLogin = lazy(() => import("../component/vendorlogin/Vendorlogin"));
const Organizer = lazy(() => import("../component/organizer/Organizer"));
const Favorites = lazy(() => import("../component/favorites/Favorites"));
const DashboardMenu = lazy(() => import("../component/dashboard/DashBoardMenu"));

const PWAScanner = lazy(()=> import("../pages/pwaScanner/Scanner"));

const EventSelection = lazy(() =>
  import("../component/eventselection/Eventselectionn")
);
const EventTicket = lazy(() =>
  import("../component/eventticket/Eventticket")
);
const ConfirmBooking = lazy(() =>
  import("../component/confirmbooking/Confirmbooking")
);
const ConfirmTicket = lazy(() =>
  import("../component/confirmticket/Confirmticket")
);
const MovieSelection = lazy(() =>
  import("../component/movieselection/Movieselection")
);
const MoviesTicket = lazy(() =>
  import("../component/moviestickets/Moviesticket")
);
const ConfirmMovieTicket = lazy(() =>
  import("../component/confirmmovieticket/ConfirmMovieTicket")
);

const renderRoute = (Component) => (
  <Suspense
    fallback={
      <div className="flex space-x-2 justify-center items-center bg-white h-screen dark:invert">
        <span className="sr-only">Loading...</span>
        <div className="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]"></div>
        <div className="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]"></div>
        <div className="h-8 w-8 bg-black rounded-full animate-bounce"></div>
      </div>
    }
  >
    <Component />
  </Suspense>
);

const navigationRoutes = [
  { name: "Home", path: "/", element: renderRoute(Home) },
  {
    name: "EventsList",
    path: "/eventslist",
    element: renderRoute(Navbar) && renderRoute(List),
  },
  {
    name: "VerifyEmail",
    path: "/verify-email/:verificationToken",
    element: renderRoute(VerifyEmail),
  },
  {
    name:"VerifyEmailOrganizer",
    path:"/verify-email-org/:verificationToken",
    element:renderRoute(VerifyEmailOrganizer)
  },
  { name: "MoviesList", path: "/movieslist", element: renderRoute(MoviesList) },
  { name: "VenueList", path: "/venuelist", element: renderRoute(venueList) },
  {
    name: "EventSelection",
    path: "/eventselection",
    element: renderRoute(EventSelection),
  },{
    name:"Organizer Page",
    path:"/organizerPage",
    element:renderRoute(ProfilePage)
  },
  {
    name: "EventTicket",
    path: "/eventticket",
    element: renderRoute(EventTicket),
  },
  {
    name: "MovieTicket",
    path: "/moviesticket",
    element: renderRoute(MoviesTicket),
  },
  {
    name: "MovieSelction",
    path: "/movieselection",
    element: renderRoute(MovieSelection),
  },
  {
    name: "ConfirmEventTicket",
    path: "/confirmticket",
    element: renderRoute(ConfirmTicket),
  },
  {
    name: "ConfirmMovieTicket",
    path: "/confirmmovieticket",
    element: renderRoute(ConfirmMovieTicket),
  },
  { name: "Booking", path: "/booking", element: renderRoute(ConfirmBooking) },
  { name: "About", path: "/about", element: renderRoute(About) },
  { name: "Register", path: "/register", element: renderRoute(Register) },
  { name: "Contact", path: "/contact", element: renderRoute(Contact) },
  { name: "Login", path: "/login", element: renderRoute(Login) },
  { name: "Favorites", path: "/favorites", element: renderRoute(Favorites) },
  {
    name: "Dashboard",
    path: "/dashboard",
    element: renderRoute(DashboardMenu),
  },
  {
    name:"ForgetPassword",
    path:"/forget-password",
    element:renderRoute(ForgetPassword)
  },
  {
    name:"ForgetPasswordOrg",
    path:"/forget-password-organizer",
    element:renderRoute(ForgetPasswordOrg)
  },
  {
    name:"RestPassword",
    path:"/reset-password/:password_reset_token",
    element:renderRoute(RestPassword)
  },
  {
    name:"RestPasswordOrg",
    path:"/reset-password-organizer/:password_reset_token",
    element:renderRoute(RestPasswordOrg)
  },
  {
    name: "ConfirmBooking",
    path: "/confirmbooking",
    element: renderRoute(ConfirmBooking),
  },

  {
    name: "RegisterOrganizer",
    path: "/registerOrganizer",
    element: renderRoute(RegisterOrganizer),
  },
  {
    name: "OrganizerLogin",
    path: "/organizerLogin",
    element: renderRoute(Organizer),
  },
  { name: "AdminLogin", path: "/adminLogin", element: renderRoute(AdminLogin) },
  {
    name: "TransactionPDF",
    path: "/transaction-pdf/:transactionId",
    element: renderRoute(TransactionPDF),
  },
];

export default navigationRoutes;
