import apiAdminPath from "../../constants/admin/auth-org-path.contant";

import axios from "../../configs/axios-instances"; 


const basicSetting = {

    getBasicSetting: ({ success, error}) => {
        const { basicSetting : { getBasicSetting } } = apiAdminPath;
        axios.getRequest({ path : getBasicSetting , success, error});
    },
    getGeneralBasicSetting: ({ success, error}) => {
        const { basicSetting : { getGeneralBasicSetting } } = apiAdminPath;
        axios.getRequest({ path : getGeneralBasicSetting , success, error});
    },
    addBasicSetting: ({payload, success, error}) => {
        const { basicSetting : { addBasicSetting } } = apiAdminPath;
        axios.postRequest({ path : addBasicSetting , payload, success, error});
    },
    updateOnlineGateways: ({ payload,success, error}) => {
        const { payment : { updateGateway } } = apiAdminPath;
        axios.putRequest({ path : updateGateway ,payload, success, error});
    },
    getOnlineGateways: ({ success, error}) => {
        const { payment : { onlineGateway } } = apiAdminPath;
        axios.getRequest({ path : onlineGateway , success, error});
    },

};

export default basicSetting;
