import apiOrganizerPath from "../../constants/organizer/auth-org-path.constant";

import axios from "../../configs/axios-instances";

const authApiOrganiser = {

    handleLoginOrg: ({ payload, success, error}) => {
        const { authOrganizer: { loginOrg } } = apiOrganizerPath;
        axios.postRequest({ path : loginOrg , payload, success, error});
    },

    handleLogoutOrg: ({ success, error}) => {
        const { authOrganizer: { logoutOrg } } = apiOrganizerPath;
        axios.postRequest({ path : logoutOrg , success, error});
    },

    handleRegisterOrg: ({ payload, success, error}) => {
        const { authOrganizer: { registerOrg } } = apiOrganizerPath;
        axios.postRequest({ path : registerOrg , payload, success, error});
    },

    handleUpdateProfileOrg: ({ payload, success, error, final }) => {
        const { authOrganizer: { updateProfileOrg } } = apiOrganizerPath;
        axios.putRequest({ path : updateProfileOrg , payload, success, error, final });
    },

    handleVerifyEmailOrg: ({ payload, success, error }) => {
        const {  authOrganizer: { verifyEmailOrg }  } = apiOrganizerPath;
        const { verificationToken } = payload;
        const pathWithParams=`${verifyEmailOrg}/${verificationToken}`;
        axios.postRequest({ path: pathWithParams, payload, success, error });
    },

    handleForgotPasswordOrg: ({ payload, success, error}) => {
        const { authOrganizer: { forgotPasswordOrg } } = apiOrganizerPath;
        axios.postRequest({ path : forgotPasswordOrg , payload, success, error});
    },

    handleResetPasswordOrg: ({ payload, password_reset_token,success, error }) => {
        const {  authOrganizer: { resetPasswordTokenOrg }  } = apiOrganizerPath;
        const pathWithParams=`${resetPasswordTokenOrg}/${password_reset_token}`;
        axios.postRequest({ path: pathWithParams, payload, success, error });
    },

    verifySessionOrg:  ({ payload, success, error, final }) => {
        const { authOrganizer : { verifySessionOrg } } = apiOrganizerPath;
        axios.postRequest({ path: verifySessionOrg, payload, success, error, final });
    },
    sendEmailOrg:  ({ payload, success, error, final }) => {
        const { authOrganizer : { sendEmailOrg } } = apiOrganizerPath;
        axios.postRequest({ path: sendEmailOrg, payload, success, error, final });
    },
};

export default authApiOrganiser;
