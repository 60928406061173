import axios from "../../configs/axios-instances";
import apiPath from "../../constants/api-path.constant";

const AdditionalApi = {
  getBlogCategoryList: ({ payload, success, error }) => {
    const {
      blogCategories: { getBlogCategoriesList },
    } = apiPath;
    axios.postRequest({
      path: getBlogCategoriesList,
      payload,
      success,
      error,
    });
  },
  addBlogCategoryList: ({ payload, success, error }) => {
    const {
      blogCategories: { addBlogCategory },
    } = apiPath;
    axios.postRequest({
      path: addBlogCategory,
      payload,
      success,
      error,
    });
  },
  updateBlogCategoryList: ({ id, payload, success, error }) => {
    const {
      blogCategories: { updateBlogCategory },
    } = apiPath;

    axios.putRequest({
      path: `${updateBlogCategory}?id=${id}`,
      payload,
      success,
      error,
    });
  },
  deleteBlogCategory: ({ id, success, error }) => {
    const {
      blogCategories: { deleteBlogCategory },
    } = apiPath;

    axios.deleteRequest({
      path: `${deleteBlogCategory}?id=${id}`,
      success,
      error,
    });
  },
  getBlogList: ({ payload, success, error }) => {
    const {
      blog: { getBlogList },
    } = apiPath;
    axios.postRequest({
      path: getBlogList,
      payload,
      success,
      error,
    });
  },
  addBlogList: ({ payload, success, error }) => {
    const {
      blog: { addBlog },
    } = apiPath;
    axios.postRequest({
      path: addBlog,
      payload,
      success,
      error,
    });
  },
  updateBlogList: ({ id, payload, success, error }) => {
    const {
      blog: { updateBlog },
    } = apiPath;

    axios.putRequest({
      path: `${updateBlog}?id=${id}`,
      payload,
      success,
      error,
    });
  },
  deleteBlog: ({ id, success, error }) => {
    const {
      blog: { deleteBlog },
    } = apiPath;

    axios.deleteRequest({
      path: `${deleteBlog}?id=${id}`,
      success,
      error,
    });
  },

  getFaqList: ({ success, error }) => {
    const {
      faq: { getFaqList },
    } = apiPath;
    axios.getRequest({
      path: getFaqList,
      success,
      error,
    });
  },
  addFaqList: ({ payload, success, error }) => {
    const {
      faq: { addFaq },
    } = apiPath;
    axios.postRequest({
      path: addFaq,
      payload,
      success,
      error,
    });
  },
  updateFaqList: ({ id, payload, success, error }) => {
    const {
      faq: { updateFaq },
    } = apiPath;

    axios.putRequest({
      path: `${updateFaq}?id=${id}`,
      payload,
      success,
      error,
    });
  },
  deleteFaq: ({ id, success, error }) => {
    const {
      faq: { deleteFaq },
    } = apiPath;

    axios.deleteRequest({
      path: `${deleteFaq}?id=${id}`,
      success,
      error,
    });
  },
  getHeaderList: ({ success, error }) => {
    const {
      header: { getHeader },
    } = apiPath;
    axios.getRequest({
      path: getHeader,
      success,
      error,
    });
  },
  addHeaderList: ({ payload, success, error }) => {
    const {
      header: { addHeader },
    } = apiPath;
    axios.postRequest({
      path: addHeader,
      payload,
      success,
      error,
    });
  },

  deleteHeader: ({ id, success, error }) => {
    const {
      header: { deleteHeader },
    } = apiPath;

    axios.deleteRequest({
      path: `${deleteHeader}?id=${id}`,
      success,
      error,
    });
  },


  
  getAboutUs: ({ success, error }) => {
    const {
      aboutUs: { getAboutUs },
    } = apiPath;
    axios.getRequest({
      path: getAboutUs,
      success,
      error,
    });
  },
  addAboutUs: ({ payload, success, error }) => {
    const {
      aboutUs: { addAboutUs },
    } = apiPath;
    axios.postRequest({
      path: addAboutUs,
      payload,
      success,
      error,
    });
  },
  updateAboutUS: ({ id, payload, success, error }) => {
    const {
      aboutUs: { updateAboutUs },
    } = apiPath;

    axios.putRequest({
      path: `${updateAboutUs}?id=${id}`,
      payload,
      success,
      error,
    });
  },
  deleteAboutUs: ({ id, success, error }) => {
    const {
      aboutUs: { deleteAboutUs },
    } = apiPath;

    axios.deleteRequest({
      path: `${deleteAboutUs}?id=${id}`,
      success,
      error,
    });
  },
  getTestimonial: ({ success, error }) => {
    const {
      testimonialSection: { getTestimonial },
    } = apiPath;
    axios.getRequest({
      path: getTestimonial,
      success,
      error,
    });
  },
  addTestimonial: ({ payload, success, error }) => {
    const {
      testimonialSection: { addTestimonial },
    } = apiPath;
    axios.postRequest({
      path: addTestimonial,
      payload,
      success,
      error,
    });
  },
  updateTestimonial: ({ id, payload, success, error }) => {
    const {
      testimonialSection: { updateTestimonial },
    } = apiPath;

    axios.putRequest({
      path: `${updateTestimonial}?id=${id}`,
      payload,
      success,
      error,
    });
  },
  deleteTestimonial: ({ id, success, error }) => {
    const {
      testimonialSection: { deleteTestimonial },
    } = apiPath;

    axios.deleteRequest({
      path: `${deleteTestimonial}?id=${id}`,
      success,
      error,
    });
  },
  getCoupons: ({ success, error }) => {
    const {
      coupons: { getCoupons },
    } = apiPath;
    axios.getRequest({
      path: getCoupons,
      success,
      error,
    });
  },
  getCoupon: ({ payload,success, error }) => {
    const {
      coupons: { getCouponByCode },
    } = apiPath;
    axios.postRequest({
      path: getCouponByCode,
      payload,
      success,
      error,
    });
  },
  getAllEvents: ({ success, error }) => {
    const {
      coupons: { getAllEventsMovies },
    } = apiPath;
    axios.getRequest({
      path: getAllEventsMovies,
      success,
      error,
    });
  },
  addCoupon: ({ payload,success, error }) => {
    const {
      coupons: { addCoupon },
    } = apiPath;
    axios.postRequest({
      path: addCoupon,
      payload,
      success,
      error,
    });
  },
  deleteCoupon: ({ code,success, error }) => {
    const {
      coupons: { deleteCoupon },
    } = apiPath;
    axios.deleteRequest({
      path: `${deleteCoupon}?code=${code}`,
      success,
      error,
    });
  },
  updateTemplate: ({ id, payload, success, error }) => {
    const {
      emailTemplates: {  updateTemplates},
    } = apiPath;

    axios.putRequest({
      path: `${updateTemplates}?id=${id}`,
      payload,
      success,
      error,
    });
  },
  getTemplates: ({ success, error }) => {
    const {
      emailTemplates: { listTemplates },
    } = apiPath;
    axios.getRequest({
      path: listTemplates,
      success,
      error,
    });
  },
  
};

export default AdditionalApi;
