import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../Pagination";
import SubscribersApi from "../../apis/adminApis/subscribers.api";
import AdditionalApi from "../../apis/adminApis/additional.api";
import { Modal } from "flowbite-react";
const AdminHeaders = () => {
  const [subscribers, setSubscribers] = useState([]);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const navigate = useNavigate();

  const [key, setKey] = useState(1);

  const handleDelete = (id) => {
    AdditionalApi.deleteHeader({
      id,
      success: (res) => {
        console.log(res.data);
        setKey(Math.random());
      },
      error: (err) => {
        console.error(err);
      },
    });
  };
  const handleAddSubmit = () => {
    navigate(`/admin/add-header`);
  };
  useEffect(() => {
    AdditionalApi.getHeaderList({
      success: (res) => {
        console.log(res.data.data);
        setSubscribers(res.data.data);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }, [key]);

  return (
    <div>
      <div>
        <div className="flex-1 p-4 overflow-x-auto">
          <div className="flex items-center space-x-2 text-sm">
            <h1 className="text-lg font-bold"></h1>
            <span className="border-r h-4 border-black mx-2"></span>
            <div className="flex items-center space-x-2 text-sm">
              <Link to="/admin" className="text-black hover:underline">
                <FaHome className="inline" />
              </Link>
              <span className="text-xl mx-1 md:mx-2">&gt;</span>
            </div>
          </div>

          <div className="flex mt-10 flex-col shadow-md rounded-lg overflow-hidden">
            <div className="flex justify-between items-center p-4 bg-gray-100">
              <div className="flex  items-center p-4 bg-gray-100">
                <h1 className="text-xl font-semibold">Announce Headers</h1>
              </div>
              <div className="flex justify-end items-center p-4 bg-gray-100">
              <button
                    className="bg-blue-500 hover:bg-blue-600 text-white rounded-md px-4 py-2"
                    onClick={handleAddSubmit}
                  >
                    Add Header
                  </button>
              </div>
            </div>
            {subscribers.length > 0 ? (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Image
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Link
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Serial_number
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {subscribers?.map((subscriber, index) => (
                    <tr key={index}>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <img src={subscriber?.attachment} />
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        {subscriber?.link}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        {subscriber?.serial_number}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap ">
                        <button
                          className="bg-red-500 hover:bg-red-600 text-white rounded-md px-3 py-1 inline-flex items-center"
                          onClick={() => handleDelete(subscriber.id)}
                        >
                          <FaTrash className="mr-2" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>
                <div className="flex flex-col items-center justify-center h-64">
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white rounded-md px-4 py-2"
                    onClick={handleAddSubmit}
                  >
                    Add Header
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHeaders;
